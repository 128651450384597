import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { useCallback } from "react";

export default function Stepper({ steps, activeStep, styles = {} }) {

    const first = steps[0];
    const last = steps[steps.length - 1];
    const inBetween = steps.slice(1, -1);

    const isActive = useCallback((step) => (step.value <= activeStep), []);
    const isDisabled = useCallback((step) => (typeof step.disabled === 'function' ? step.disabled() : step.disabled), []);
    const onClickStep = useCallback((step) => {
        if (isDisabled(step))
            return;
        step.onClick();
    }, [])

    const styling = Object.assign({
        icon: "",
        label: "",
        sublabel: "",
    }, styles)

    return <ol className="flex border p-4 items-center rounded-md w-full text-sm font-medium text-center text-gray-600 dark:text-gray-600 sm:text-base">
        <li id={`step${1}`} className="flex group md:w-full items-center text-teal-600 dark:text-teal-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-teal-500 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-teal-700" >
            <div onClick={() => onClickStep(first)} className={cn("", isDisabled(first) ? "cursor-not-allowed" : "cursor-pointer")}>
                <div className="flex justify-center items-center gap-2 after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                    <Icon icon={`tabler:square-rounded-number-1-filled`} className={cn("text-xl", styling.icon)} />
                    <span className={cn("group-hover:text-teal-600 md:text-nowrap md:text-lg text-xs text-wrap font-semibold", styling.label, isActive(first) ? "!text-teal-600" : "", isDisabled(first) ? "group-hover:!text-gray-500 " : "")}>{first.label}</span>
                </div>
                <small className={cn("text-xs md:inline-flex hidden text-nowrap", styling.sublabel)}>{first.sublabel}</small>
            </div>
        </li>
        {inBetween?.length > 0 && inBetween.map((step, index) => <li id={`step${index + 2}`} key={index} className="flex group md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-teal-500 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-teal-700">
            <div onClick={() => onClickStep(step)} className={cn("", isDisabled(step) ? "cursor-not-allowed" : "cursor-pointer")}>
                <div className="flex justify-center items-center gap-2 after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                    <Icon icon={`tabler:square-rounded-number-${index + 2}-filled`} className={cn("text-xl", styling.icon)} />
                    <span className={cn("group-hover:text-teal-600 md:text-nowrap md:text-lg text-xs text-wrap font-semibold", styling.label, isActive(step) ? "!text-teal-600" : "", isDisabled(step) ? "group-hover:!text-gray-500 " : "")}>{step.label}</span>
                </div>
                <small className={cn("text-xs md:inline-flex hidden text-nowrap", styling.sublabel)}>{step.sublabel}</small>
            </div>
        </li>)}
        <li id={`step${steps.length}`} className="flex group items-center">
            <div onClick={() => onClickStep(last)} className={cn("", isDisabled(last) ? "cursor-not-allowed" : "cursor-pointer")}>
                <div className="flex justify-center items-center gap-2 after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                    <Icon icon={`tabler:square-rounded-number-${steps.length}-filled`} className={cn("text-xl", styling.icon)} />
                    <span className={cn("group-hover:text-teal-600 md:text-nowrap md:text-lg text-xs text-wrap font-semibold", styling.label, isActive(last) ? "!text-teal-600" : "", isDisabled(last) ? "group-hover:!text-gray-500 " : "")}>{last.label}</span>
                </div>
                <small className={cn("text-xs md:inline-flex hidden text-nowrap", styling.sublabel)}>{last.sublabel}</small>
            </div>
        </li>
    </ol>

}
